@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Montserrat:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', 'Montserrat',;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
}

.Button
{
  color:wheat;
}